.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2999;
}

.spinner {
  width: 150px;
  height: 150px;
  border-top: 3.5px solid #00326D;
  border-right: 3.8px solid #00326D;
  border-bottom: 4px solid transparent;
  border-radius: 50%;
  animation: rotation 1.4s linear infinite;
}

.spinner-border-color {
  border-top-color: #00326D;
  border-right-color: #00326D;
  border-bottom-color: transparent;
}

.spinner-border-color-dark {
  border-top-color: #00326D;
  border-right-color: #00326D;
  border-bottom-color: transparent;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 18px;
  font-family: "Helvetica";
  border: none;
  border-radius: none;
  animation: unset;
}

.progress-text-color {
  color: #00326D;
}

.progress-text-dark-color {
  color: #00326D;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}